/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    ol: "ol",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "The eD-TEC eD 32 c-ultra RIB: A Game-Changer in Electric Boating"), "\n", React.createElement(_components.h2, null, "Introduction"), "\n", React.createElement(_components.p, null, "The eD-TEC eD 32 c-ultra RIB is an all-electric high-performance rigid inflatable boat introduced by German company eD-TEC. It offers a top speed of 50 knots and a range of up to 30 nautical miles on a single charge."), "\n", React.createElement(_components.h2, null, "Features"), "\n", React.createElement(_components.p, null, "The boat is powered by eD-TEC's innovative eD-QDrive 1 units, delivering impressive power and performance. It features a sleek design, advanced navigation capabilities, and the option for a foil-assist system."), "\n", React.createElement(_components.h2, null, "Market Impact"), "\n", React.createElement(_components.p, null, "The eD 32 c-ultra RIB is designed for superyacht tender operations and is expected to be a game-changer in the boating market."), "\n", React.createElement(_components.h2, null, "References"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://powerboat.world/news/272955/eD-TEC-unveil-the-new-eD-32-c-ultra-RIB"
  }, "Powerboat World - All-electric performance pioneers eD-TEC unveil the new high ...")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://yachtharbour.com/news/a-new-era-in-electric-performance-boating--ed-tec-unveils-revolutionary-ed-32-c-ultra-rib-7094"
  }, "Yacht Harbour - A New Era in Electric Performance Boating: eD-TEC ...")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://robbreport.com/motors/marine/ed-tec-electric-rib-1235558246/"
  }, "Robb Report - eD-TEC's New Electric RIB Can Hit a Ferocious 50 Knots at Full Tilt")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://megayachtnews.com/2024/03/high-speed-32-c-ultra-electric-rib-superyacht-tender/"
  }, "Mega Yacht News - High-Speed 32 c-ultra Electric RIB Can Be Your Next Superyacht Tender")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
